body {
  /* zoom: 0.9; */
  /* -moz-transform: scale(0.9);
   -moz-transform-origin: 0 0; */
  background: #edf0f7 !important;
}

.hidescroll::-webkit-scrollbar {
  display: none !important;
}

.list-item-active:before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 14px solid transparent;
  border-right: 0;
  border-bottom: 14px solid transparent;
  border-left: 14px solid #e04e2c;
  position: absolute;
  right: -14px;
  top: 14px;
}

.rider-list.active {
  background-color: #e3552e;
  color: #ffffff;
  position: relative;
}

.rider-list.active:before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 14px solid transparent;
  border-right: 0;
  border-bottom: 14px solid transparent;
  border-left: 14px solid #e04e2c;
  position: absolute;
  right: -13px;
  top: 32px;
}

.checkmark {
  display: flex;
  box-shadow: 0 0 7px 0px #e3e3e3;
  border-radius: 30px;
  padding: 15px 16px;
  align-items: center;
  margin-bottom: 10px;
  background-color: #fff;
}

.container input:checked ~ .checkmark {
  background-color: #e04e2c;
}

.radio-option {
  background-color: #206bad !important;
}

.checkmark:after {
  position: absolute;
  display: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.img_container {
  position: absolute !important;
  height: 85% !important;
  width: 85% !important;
  border-radius: 50% !important;
}

.radio-checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.radio-checkbox input[type="radio"] {
  display: none;
}

.checkmark_group {
  display: inline-block;
  border: 2px solid #637381;
  border-radius: 5px;
  padding: 8px;
  margin-right: 10px;
  position: relative;
  background-color: white;
}

.radio-checkbox input[type="radio"]:checked + .checkmark_group {
  background-color: #e65100;
  border-color: #e65100;
}

.checkmark_group:after {
  content: "";
  position: absolute;
  top: 3px;
  left: 6px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  display: none;
}

.radio-checkbox input[type="radio"]:checked + .checkmark_group:after {
  display: block;
}

.filter_button {
  height: 35px;
  margin-top: 5px;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 700;
  color: #a6a6a6;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  background-color: #fff;
  margin-right: 5px;
}

.filter_button:hover {
  background-color: #e7e7e7;
}

.filter_button_apply {
  height: 35px;
  margin-top: 5px;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  background-color: #e7e7e7;
}

.filter_button_apply:hover {
  background-color: #e04e2c !important;
}

.AccordionDetails_button {
  margin-top: 5px;
  height: 30px;
  color: #7a7a7a;
  border-color: #eaeaea;
  border-radius: 30px;
  padding: 0px 10px;
  background: #fff;
  margin-right: 5px;
  cursor: pointer;
}

.AccordionDetails_button:hover {
  background-color: #e04e2c !important;
  color: white !important;
}

.AccordionDetails_textField {
  height: 45px;
  width: 100%;
  border: 1px solid #eee;
  color: #737373;
  font-size: 14px;
  font-weight: 500;
}

.AccordionDetails_activebutton {
  margin-top: 5px;
  height: 30px;
  color: #7a7a7a;
  border-color: #eaeaea;
  border-radius: 30px;
  padding: 0px 10px;
  background: #fff;
  margin-right: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
}

.AccordionDetails_inactivebutton {
  margin-top: 5px;
  height: 30px;
  color: #7a7a7a;
  border-color: #eaeaea;
  border-radius: 30px;
  padding: 0px 10px;
  background: #fff;
  margin-right: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  display: none;
}

.active_input {
  padding: unset;
  width: 324px;
  border: unset;
  outline: unset;
}

.inactive_input {
  display: none !important;
}

iframe {
  display: none;
}

.map-grid iframe {
  pointer-events: all;
}

.centered-tab .MuiTabs-flexContainer {
  justify-content: center;
}

.centered-tab .MuiTabs-flexContainer > button {
  flex: 1;
  margin: 0;
}

.tabPanel {
  position: relative;
}

.flatCard {
  padding-left: 16px !important;
}

.createBtn {
  position: absolute !important;
  top: -36px;
  right: 0;
}

.MuiTouchRipple-root {
  display: none !important;
}

.tabPanel > .MuiBox-root {
  padding-left: 0;
  padding-right: 0;
  height: 100%;
}

.custom-calendar {
  width: 100%;
  padding: 10px 10px;
  border-radius: 8px;
  border: 1px solid rgba(145, 158, 171, 0.32);
}

label:hover {
  background-color: transparent !important;
}

.iconBtn {
  width: 150px;
  height: 150px;
  border: 1px dashed rgb(196, 196, 196) !important;
  border-radius: 15px !important;
}

/* .iconBtn label {} */

.para_label {
  color: #919eab;
  font-size: 16px !important;
  margin-bottom: 10px !important;
}

.para_text {
  border-radius: 10px;
  border-color: #737373;
}

input:focus-visible,
*:focus {
  outline: none;
}

.react-datetime-picker__button svg {
  stroke: #868686;
}

.custom-calendar .react-datetime-picker__wrapper {
  border: 0;
}

.custom-calendar
  .react-datetime-picker__wrapper
  .react-datetime-picker__inputGroup {
  padding: 0;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.sideBar-menus a {
  height: 80px;
}

.lgArea {
  flex-grow: 1;
  position: relative;
  display: flex;
}

.floatingGrid {
  position: absolute;
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.stepKm {
  position: absolute;
  background: #ebebeb;
  font-family: "Cabin", sans-serif !important;
  border-radius: 20px;
  padding: 2px 5px;
  color: #000;
  font-size: 10px;
  transform: translate(-10px, -15px);
}

.orderLists {
  flex: 0 0 100px;
  padding: 10px 0px 10px 0;
  background: white;
  min-width: 400px;
  max-width: 650px;
  overflow: auto;
  height: 100%;
}

.MuiInput-underline::before {
  border-bottom: 0 !important;
}

.userListToolbar {
  width: 75%;
}

.userListToolbar_withDate {
  width: 56%;
  position: relative;
  right: 1%;
}

.MuiInputBase-root {
  height: 45px !important;
}

.orderDetails .orderTime .displayTime {
  flex-wrap: wrap;
  width: 100%;
}

.otp_Input_created {
  height: 45px !important;
  width: 45px !important;
  border: 1px solid #f4f6f8 !important;
  color: #002236 !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  background-color: #e4e4e4 !important;
  border-radius: 12px !important;
  text-align: center !important;
  box-shadow: 0px 4px 50px 0px #0000000d;
  font-family: "Cabin", sans-serif !important;
}

.otp_Input_created:focus {
  border: 1px solid #e3552e !important;
  outline: none !important;
}

.otp_input_box {
  margin: 0 auto !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.rotation {
  transform: rotate(-90deg);
  width: 10px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.borderCard {
  border: 1px solid #e04e2c;
}

.scrollHeight {
  position: relative;
}

.orderDetails .orNumPkgType p {
  display: flex;
  align-items: center;
}

.newBooking {
  background: #e3552e;
  color: #fff;
  border-radius: 10px;
  padding: 2px 5px;
  font-size: 10px;
  display: inline-block;
  margin-left: 5px;
}

.viewNewBookingBtn {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #1d313f;
  color: #fff;
  border-radius: 20px;
  padding: 8px 20px;
  font-size: 10px;
  cursor: pointer;
}

.MuiTabs-indicator {
  height: 1px !important;
}

@media (min-width: 768px) {
  .mainBody {
    /* padding-top: 70px !important; */
    padding-bottom: 0px !important;
    padding-left: 0 !important;
    padding-right: 0px !important;
  }

  .sideBar-Drawer {
    position: fixed;
    height: 100%;
    top: 0;
    padding-top: 60px;
    background: #d9d9d9;
    /* padding-top: 80px; */
    /* height: calc(100vh - 70px) !important; */
  }

  .sideBar {
  }

  .sideBar,
  .sideBar-Drawer {
    width: 90px !important;
  }

  .bookingList {
    height: calc(100vh) !important;
  }

  .simplebar-content {
    height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    width: 100% !important;
    margin-bottom: 0px !important;
  }

  /* .navIcon-area>div {
      width: 30px !important;
      height: 30px !important;
   } */

  .sideBar-menus {
    flex: 1;
    width: 100%;
  }

  .sideBar-menus > ul {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
  }

  .delIcon {
    cursor: pointer;
    padding: 8px;
    margin-left: 20px;
  }

  .delIcon .delIcon-body {
    width: 30px !important;
    height: 30px !important;
    display: inline-block;
    background-color: #000;
    mask: url(../src/images/SignOut.svg) no-repeat center/contain;
    -webkit-mask: url(../src/images/SignOut.svg) no-repeat center/contain;
  }

  .orderDetails {
    display: flex;
    padding: 8px 14px;
    position: relative;
  }

  .ratingStyle svg {
    width: 14px !important;
    height: 14px !important;
  }

  .refgt {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    padding-left: 50px;
    padding-bottom: 10px;
  }

  .orderDetails .pkgTypeIcn {
    font-size: 50px;
    position: absolute;
    border-radius: 50%;
    background: #fff;
    color: #9d9d9d;
    width: 60px;
    min-width: 60px;
    height: 60px;
    transform: translate(-24px, -20px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Inter, sans-serif;
    font-weight: 900;
  }

  .orderDetails .orNumPkgType p {
    /* font-size: 12px; */
    /* font-size: 0.625vw; */
  }

  .orderDetails .pkgTypeIcn img {
    height: 26px;
  }

  .orderDetails .orderTime {
    display: flex;
    align-items: start;
  }

  .orderDetails .orderTime p {
    /* font-size: 10px; */
    font-size: 0.52vw;
  }

  /* .orderDetails .orderTime p:last-of-type {
    padding-left: 10px;
      margin-left: 10px;
    border-left: 1px solid #97aec8;
  } */

  /* .list-item-active .orderDetails .orderTime p:last-of-type {
    border-color: #fff;
  } */

  .orderDetails .orderTime .displayTime {
    display: flex !important;
    justify-content: center !important;
    align-items: flex-start !important;
    gap: 4px;
    flex-wrap: wrap;
    width: 100%;
  }

  .orderStatus {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 14px;
  }

  .orderStatus p {
    font-size: 12px;
    margin-top: 8px;
  }

  .orderStatus p strong {
    text-transform: capitalize;
  }

  .orderStatusIcon {
    display: flex;
    background: #75f8b1;
    color: #000;
    align-items: center;
    padding: 5px 10px;
    width: 110px;
    font-size: 12px;
    font-weight: 600;
  }

  .orderStatusIcon img {
    margin-right: 5px;
    height: 20px;
  }
}

@media (min-width: 1024px) {
  .topHeader {
    height: 50px !important;
  }

  .topHeader img {
    height: 25px !important;
  }

  .bodyBox,
  .sideBar-Drawer {
    padding-top: 50px !important;
  }

  .sideBar-menus a {
    height: 45px;
  }

  .sideBar,
  .sideBar-Drawer {
    width: 50px !important;
  }

  .iconDiv {
    width: 25px !important;
    height: 25px !important;
  }

  .navIcon-area {
    width: auto !important;
    height: auto !important;
    min-width: auto !important;
  }

  .orderLists {
    flex: 0 0 100px;
    padding: 10px 0px 10px 0;
    background: white;
    min-width: 300px;
    max-width: 350px;
    overflow: auto;
    height: 100%;
  }

  .scrollHeight {
    height: calc(100vh - 200px);
  }

  .tabLabel {
    font-size: 13px !important;
  }

  .mapContainer {
    height: calc(100vh - 50px) !important;
  }

  .gridItem {
    min-width: 375px;
    max-width: 375px;
  }

  .searchField {
    font-size: 14px !important;
  }

  .MuiInputBase-root {
    height: 36px !important;
  }
  .css-r4f2lo-MuiFormLabel-root-MuiInputLabel-root {
    transform: translate(15px, 8px) scale(1) !important;
  }

  .orderDetails {
    padding: 8px 12px;
  }

  .pIcn {
    font-size: 35px !important;
  }

  .orderStatus p {
    font-size: 10px;
  }

  .orderStatus img {
    width: 36px !important;
  }

  .orderStatusIcon {
    padding: 2px 8px;
    width: auto;
    font-size: 10px;
  }

  .orderStatusIcon img {
    width: 15px !important;
  }

  .refgt {
    padding-left: 50px;
    padding-bottom: 6px;
  }

  .orderDetails .orNumPkgType p {
    font-size: 12px;
  }

  .orderDetails .orNumPkgType .displayTime p {
    font-size: 8px !important;
  }

  .orderDetails .orderTime .displayTime img {
    width: 18px;
    height: 18px;
  }

  .orderDetails .orderTime .displayTime p {
    font-size: 8px !important;
  }

  .statusChip {
    padding: 4px 8px !important;
    width: auto;
  }

  .statusChip img {
    width: 15px;
  }

  .statusChip p {
    font-size: 10px !important;
  }

  .dtDetail {
    font-size: 12px !important;
  }

  .threeDotIcn {
    width: 16px;
  }

  .sameSizeP {
    font-size: 12px !important;
  }

  .sameSizeP.displayName {
    font-size: 14px !important;
  }

  .avtar {
    height: 40px !important;
    width: 40px !important;
  }

  .statusMsg {
    font-size: 10px !important;
  }

  .icnBtns {
    height: 25px !important;
    width: 25px !important;
  }

  .totalDistance {
    font-size: 12px !important;
  }

  .taskTimeline {
    height: calc(100vh - 388px);
  }

  .notCompletedIcon {
    width: 24px;
  }

  .toPay {
  }

  .fakeMap {
    min-width: 385px;
    max-width: 385px;
  }

  .dropdownMenuItem {
    font-size: 12px !important;
  }

  .riderName {
    font-size: 12px !important;
  }

  .riderCode {
    font-size: 8px !important;
  }

  .orderDetails .pkgTypeIcn {
    position: absolute;
    width: 60px;
    min-width: 60px;
    height: 60px;
    transform: translate(-20px, -28px);
  }
}

@media (min-width: 1450px) {
  .orderStatus p {
    /* font-size: 14px; */
    /* font-size: 0.73vw; */
  }

  .orderDetails .orderTime p:last-of-type {
    padding: 0;
    border: 0;
    /* max-width: 50px; */
  }

  .orderDetails .pkgTypeIcn img {
    height: 35px;
  }

  .orderDetails .pkgTypeIcn {
    position: absolute;
    width: 60px;
    min-width: 60px;
    height: 60px;
    transform: translate(-20px, -28px);
  }

  .orderDetails .orNumPkgType p {
    /* font-size: 14px; */
    /* font-size: 0.73vw; */
  }

  .orderDetails .orderTime p {
    /* font-size: 12px; */
    /* font-size: 0.625vw; */
  }

  .orderDetails .orderTime {
    display: block;
    margin-right: auto;
    margin-top: 3px;
  }

  .bookingList {
    height: calc(100vh) !important;
  }

  .mainBody {
    /* padding-top: 80px !important; */
  }

  .sideBar-Drawer {
    /* height: calc(100vh) !important; */
  }

  /* .sideBar,
   .sideBar-Drawer {
      width: 90px !important;
   } */

  .simplebar-content {
    height: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    width: 100% !important;
    margin-bottom: 0px !important;
  }

  /* .navIcon-area,
   .navIcon-area>div {
      width: 40px !important;
      height: 40px !important;
   } */

  .sideBar-menus {
    flex: 1;
    width: 100%;
  }

  .sideBar-menus > ul {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
  }

  .delIcon .delIcon-body {
    width: 40px !important;
    height: 40px !important;
  }

  .topHeader {
    height: 60px !important;
  }

  .orderDetails .orderTime .displayTime {
    flex-wrap: wrap;
    width: 100%;
  }

  .bodyBox,
  .sideBar-Drawer {
    padding-top: 60px !important;
  }

  .orderLists {
    min-width: 400px;
    max-width: 450px;
  }

  .sideBar,
  .sideBar-Drawer {
    width: 90px !important;
  }

  .sideBar-menus a {
    height: 80px;
  }

  .iconDiv {
    width: 45px !important;
    height: 45px !important;
  }

  .scrollHeight {
    height: calc(100vh - 210px);
  }

  .mapContainer {
    height: calc(100vh - 60px) !important;
  }

  .tabLabel {
    font-size: 13px !important;
  }

  .gridItem {
    min-width: 450px;
    max-width: 450px;
  }

  .taskTimeline {
    height: calc(100vh - 383px);
  }

  .notCompletedIcon {
    width: 28px;
  }

  .fakeMap {
    min-width: 460px;
    max-width: 460px;
  }

  .riderName {
  }

  .riderCode {
  }
}

@media (max-width: 1280px) {
  .newGroups {
    flex-direction: column;
  }
}

@keyframes splashAnimation {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.splash-effect {
  animation: splashAnimation 1s ease-in-out;
}

.css-12vv5uf-MuiButtonBase-root-MuiChip-root:hover {
  background-color: #e65100ad !important;
}

.css-9wrm04-MuiButtonBase-root-MuiChip-root:hover {
  background-color: rgb(0 0 0 / 18%) !important;
}